import { createSlice } from "@reduxjs/toolkit";

const downloadZipSlice = createSlice({
    name: "zip",
    initialState: {
        zipFileUrl: null,  // New property to store the zip file URL
    },
    reducers: {
        setZipFileUrl: (state, action) => {
            state.zipFileUrl = action.payload.zipFileUrl;
        },
        clearZipFileUrl: (state) => {
            state.zipFileUrl = null; // Clear the URL after download to manage memory
        }
    },
});

export const { setZipFileUrl, clearZipFileUrl } = downloadZipSlice.actions;
export default downloadZipSlice.reducer;