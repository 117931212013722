import React, { useEffect } from "react";
import { Card, Col, Container, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getQuery } from "../../features/apiCall";
import { Link, useParams } from "react-router-dom";
import "../../utils/style.scss";
import { setCurrentPage } from "../../features/generalSlice";
import { FaDownload } from "react-icons/fa";

const ViewQuery = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { token } = useSelector((state) => state.auth);
  const { query } = useSelector((state) => state.query);

  useEffect(() => {
    dispatch(setCurrentPage({ currentPage: "Queries" }));
  }, []);

  useEffect(() => {
    if (token) getQuery(dispatch, token, id);
  }, []);

  const handleDownload = async () => {
    try {
      // Fetch the image as a Blob
      const response = await fetch(`${process.env.REACT_APP_URL}/${query?.image}`, {
        mode: 'cors', // Ensure CORS is allowed
      });

      // Check if the request was successful
      if (!response.ok) {
        throw new Error("Failed to fetch the image");
      }

      // Convert response to Blob
      const blob = await response.blob();

      // Create a temporary URL for the blob
      const blobURL = URL.createObjectURL(blob);

      // Create a link element and set download attributes
      const link = document.createElement("a");
      link.href = blobURL;
      link.setAttribute("download", "query-image.jpg");

      // Append link to the DOM, click to download, and remove after download starts
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Clean up the temporary URL
      URL.revokeObjectURL(blobURL);
    } catch (error) {
      console.error("Image download failed:", error);
    }
  };


  return (
    <Container fluid>
      <Card className="card">
        <Card.Header className="card-view">
          <Card.Title>Query - Details</Card.Title>
        </Card.Header>
        <Card.Body className="card-view">
          <Row>
            <Col md={4}>
              <p className="mb-0">
                <strong>Submitted By</strong>
              </p>
              <p>{query?.name}</p>
            </Col>
            <Col md={4}>
              <p className="mb-0">
                <strong>Email</strong>
              </p>
              <p>{query?.email}</p>
            </Col>
            <Col md={4}>
              <p className="mb-0">
                <strong>Mobile Number</strong>
              </p>
              <p>{query?.mobile}</p>
            </Col>
            {query?.company_name && (
              <Col md={4}>
                <p className="mb-0">
                  <strong>Company Name</strong>
                </p>
                <p>{query?.company_name}</p>
              </Col>
            )}
            {/* <Col md={4}>
              <p className="mb-0">
                <strong>Address</strong>
              </p>
              <p>{query?.address}</p>
            </Col> */}
            {query?.createdAt && (
              <Col md={4}>
                <p className="mb-0">
                  <strong>Submitted At</strong>
                </p>
                <p>
                  {new Date(query.createdAt).toLocaleDateString() +
                    " " +
                    new Date(query.createdAt).toLocaleTimeString("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                    })}
                </p>
              </Col>
            )}
            <Col md={4}>
              <p className="mb-0">
                <strong>Message</strong>
              </p>
              <p>{query?.message}</p>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <p className="mb-3">
                <strong>Image</strong>
              </p>
              <div className="image-container">
                {query?.image ? (
                  // If query.image is not empty, show the image
                  <img
                    className="query-image"
                    src={`${process.env.REACT_APP_URL}/${query?.image}`}
                    alt="img"
                  />
                ) : (
                  // If query.image is empty, show a placeholder or text
                  <p style={{ color: "#caa257" }}>Image not Provided</p>
                )}
                <Link
                  onClick={handleDownload}
                  style={{
                    backgroundColor: "#caa257",
                    border: "none",
                  }}
                  className="btn btn-dark download-btn"
                >
                  <FaDownload />
                </Link>
              </div>
            </Col>

          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ViewQuery;
